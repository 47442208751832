<template>
  <div class="container">
    <a-tabs v-model="activePart" @change="handleChangeTab">
      <a-tab-pane :key="0" v-if="business.businessType === IdType.BUSINESS">
        <span class="tab-title" slot="tab">
          <span class="title">企业信息</span>
        </span>
        <!-- 企业基本信息 -->
        <CompanyBasicInfo ref="refComp_0" />
      </a-tab-pane>
      <a-tab-pane :key="0" v-if="business.businessType === IdType.PERSON">
        <span class="tab-title" slot="tab">
          <span class="title">个人信息</span>
        </span>
        <!-- 企业基本信息 -->
        <PersonBasicInfo ref="refComp_0" />
      </a-tab-pane>
      <!--      <a-tab-pane :key="1" tab="企业联系人" v-if="business.businessType === IdType.BUSINESS">-->
      <!--        <Contacts ref="refComp_1" />-->
      <!--      </a-tab-pane>-->
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurBusiness } from '@/util/utils'
import { IdType } from '@/util/constant'
import Contacts from '@/views/businessManagement/companyInfo/contacts.vue'
import CompanyBasicInfo from '@/views/businessManagement/companyInfo/companyBasicInfo.vue'
import PersonBasicInfo from '@/views/businessManagement/companyInfo/personBasicInfo.vue'

export default {
  computed: {
    IdType() {
      return IdType
    }
  },
  components: {
    PersonBasicInfo, CompanyBasicInfo, Contacts
  },
  data() {
    return {
      business: {},
      activePart: 0
    }
  },
  created() {
    this.business = getCurBusiness()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeTab(e) {
      this.$nextTick(() => {
        this.$refs[`refComp_${ e }`] && this.$refs[`refComp_${ e }`].handleInitData()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 12px 25px 16px;
  box-sizing: border-box;
  background: #fff;
  min-height: 820px;
}

::v-deep .ant-tabs-tab {
  padding: 12px 0;

  .tab-title {
    display: flex;
    align-items: center;
  }

  span.title {
    font-size: 16px;
    margin-right: 8px;
    font-weight: 500;
  }
}
</style>
